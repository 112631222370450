<div class="flex">
  <app-avatar [initials]="generateInitials()"></app-avatar>
  <div class="pl-2 pt-1">
    <div
      class="flex items-center text-sm text-primary"
      [ngClass]="{ 'font-semibold': highlightUsername }"
    >
      {{ username }}
    </div>
    <div class="break-all text-sm font-normal text-grey">{{ email }}</div>
  </div>
</div>
