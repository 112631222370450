import { Component, Input } from '@angular/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-user-card',
  standalone: true,
  imports: [AvatarComponent, CommonModule],
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.css',
})
export class UserCardComponent {
  @Input({ required: true }) username: string;
  @Input({ required: true }) email: string;
  @Input() highlightUsername = true;

  generateInitials() {
    if (!this.username) {
      return '';
    }

    const words = this.username.split(' ');
    const initials = words.map((word) => word[0]).join('');
    return initials.toUpperCase();
  }
}
