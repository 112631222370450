<svg class="size-[40px]" viewBox="0 0 100 100">
  <circle cx="50" cy="50" r="45" fill="#F7F8F9"></circle>
  <text
    x="50"
    y="55"
    dominant-baseline="middle"
    text-anchor="middle"
    font-size="30"
    fill="#475467"
  >
    {{ initials }}
  </text>
</svg>
