import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { TranslocoPipe } from '@jsverse/transloco';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserCardComponent } from '../../../shared/components/user-card/user-card.component';
import { AnalyticsService } from '../../services/analytics/analytics.service';

export interface SidebarItem {
  titleKey: string;
  url: string;
  iconName?: string;
}

export interface SidebarPanelItem extends SidebarItem {
  children: SidebarItem[];
}

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatIcon,
    MatButtonModule,
    MatRippleModule,
    TranslocoPipe,
    RouterLink,
    RouterLinkActive,
    UserCardComponent,
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent {
  @Input({ required: true }) sidebarItems: Array<SidebarItem | SidebarPanelItem> | null;
  @Input({ required: true }) userDetails: { username?: string; email?: string } | null;
  @Input() infoCard: TemplateRef<Element>;
  @Output() logoutEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly analyticsService: AnalyticsService) {}

  isPanelType(item: SidebarItem | SidebarPanelItem): item is SidebarPanelItem {
    return !!(item as SidebarPanelItem).children;
  }

  logout(): void {
    this.logoutEvent.emit();
  }

  trackClickEvent(menuItem: string) {
    this.analyticsService.track('menu_item_clicked', { menuItem });
  }
}
