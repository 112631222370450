<div class="h-full w-80 border border-grey-lightGrey">
  <header class="h-8 items-center">
    <img src="assets/logo.svg" alt="Logo" class="ml-4 mt-6 h-[34px]" />
  </header>
  <div class="overflow-hidden">
    <mat-sidenav-container class="hvs-sidenav-container">
      <mat-sidenav-content class="pl-4">
        <mat-nav-list>
          @for (item of sidebarItems; track item) {
            @if (isPanelType(item)) {
              <mat-expansion-panel expanded #expansionPanel>
                <mat-expansion-panel-header matRipple class="mr-3.5">
                  <mat-panel-title class="pr-4">
                    <mat-icon class="material-icons-outlined hvs-icon mr-3">{{
                      item.iconName
                    }}</mat-icon
                    >{{ item.titleKey | transloco }}</mat-panel-title
                  >
                </mat-expansion-panel-header>
                <mat-nav-list>
                  @for (
                    innerItem of item.children;
                    track innerItem;
                    let i = $index
                  ) {
                    <a
                      mat-list-item
                      #matListItem
                      [routerLink]="innerItem.url"
                      routerLinkActive="active"
                      (click)="trackClickEvent(innerItem.titleKey)"
                    >
                      @if (innerItem.iconName) {
                        <mat-icon class="material-icons-outlined">{{
                          innerItem.iconName
                        }}</mat-icon>
                      }
                      {{ innerItem.titleKey | transloco }}</a
                    >
                  }
                </mat-nav-list>
              </mat-expansion-panel>
            } @else {
              <a
                mat-list-item
                [routerLink]="item.url"
                routerLinkActive="active"
                (click)="trackClickEvent(item.titleKey)"
              >
                @if (item.iconName) {
                  <mat-icon class="material-icons-outlined mr-2 text-grey">{{
                    item.iconName
                  }}</mat-icon>
                }
                <span>
                  {{ item.titleKey | transloco }}
                </span>
              </a>
            }
          }
        </mat-nav-list>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <footer class="absolute bottom-0 w-80 p-4 pb-6">
    <ng-container *ngTemplateOutlet="infoCard"></ng-container>
    <div class="mt-5 text-grey-lightGrey">
      <mat-divider class="material-divider w-full pb-6"></mat-divider>
    </div>
    <div class="flex w-full items-center justify-between">
      @if (userDetails && userDetails.email && userDetails.username) {
        <app-user-card
          [username]="userDetails.username"
          [email]="userDetails.email"
          class="mr-2"
        />
      }
      <mat-icon
        class="text-grey-dark hvs-icon cursor-pointer pr-5 font-thin"
        (click)="logout()"
        >logout</mat-icon
      >
    </div>
  </footer>
</div>
